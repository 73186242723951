export default function Videos() {
	$( '#videos>article>a' ).on( 'click', function ( e ) {
		e.preventDefault();
		const href = $( this ).attr( 'href' );
		const title = $( this ).find( 'span' ).text();
		const video_player = $( '#video-player' );

		video_player.find( 'iframe' ).attr( 'src', href + '?autoplay=1' );
		video_player.find( '#video-title' ).text( title );
	} );
}