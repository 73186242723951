import Nav from './modules/nav';
import RecordsSlider from './modules/records-slider';
import VideosSlider from './modules/videos-slider';
import BuyGroup from './modules/buy-group';
import Videos from './modules/videos';
import NewsSlider from './modules/news-slider';
import ArtistSliders from './modules/artist-sliders';
import Newsletter from './modules/newsletter';

jQuery( document ).ready( function ( $ ) {
	Nav();
	RecordsSlider();
	BuyGroup();
	VideosSlider();
	Videos();
	NewsSlider();
	ArtistSliders();
	Newsletter();
} );