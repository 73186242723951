require( 'owl.carousel' );
export default function NewsSlider() {
	$( '.news-slider .owl-carousel' ).owlCarousel( {
		items: 2,
		margin: 100,
		loop: true,
		dots: false,
		nav: true,
		navText: [
			'<i class="fas fa-arrow-alt-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fas fa-arrow-alt-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		],
		responsive: {
			0: {
				margin: 0,
				items: 1,
			},
			992: {
				items: 2,
				margin: 100
			}
		}

	} );
}