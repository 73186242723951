require( 'owl.carousel' );
export default function VideosSlider() {
	$( '#home-videos .owl-carousel' ).owlCarousel( {
		items: 1,
		margin: 0,
		loop: true,
		dots: false,
		nav: true,
		video: true,
		navText: [
			'<i class="fas fa-arrow-alt-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fas fa-arrow-alt-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		]
	} );
}