import 'magnific-popup';
import Cookies from 'js-cookie';

export default function Newsletter() {

	if ( Cookies.get( 'newsletter' ) !== '1' ) {
		$.magnificPopup.open( {
			items: {
				src: '#newsletter-modal',
				type: 'inline'
			}
		} );
		Cookies.set( 'newsletter', '1', { expires: 365 } );
	}


	$( '.newsletter-link' ).on( 'click', function ( e ) {
		e.preventDefault();
		$.magnificPopup.open( {
			items: {
				src: '#newsletter-modal',
				type: 'inline'
			}
		} );
	} );

	$( '#records-newsletter-sign-up-v1-jan-2020' ).on( 'submit', function ( e ) {
		// Prevent default browser actions on form submission.
		e.preventDefault();
		// Get all the form user submitted data and get it ready to be sent.
		const data = $( this ).serialize();
		// POST all the data to the SMF submission endpoint URL.
		$.ajax( {
			type: 'POST',
			url: 'https://subs.sonymusicfans.com/submit',
			dataType: 'json',
			data: data,
			xhrFields: {
				withCredentials: false
			},
			success: function ( data ) {
				$( '#newsletter-modal .form' ).hide();
				$( '#newsletter-modal .thank-you' ).show();
			},
			error: function ( err ) {
				alert( 'An error has occurred!' );
			}
		} );
	} );
}